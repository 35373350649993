import Highway from '@dogstudio/highway';

export default class PageContact extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageContact');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.formContact = this.DOM.view.querySelector('#contactForm');
    this.DOM.formMessage = this.DOM.view.querySelector('#formMessage');

    this.DOM.fields = {
      profession: this.DOM.view.querySelector('#profession'),
      firstname: this.DOM.view.querySelector('#firstname'),
      name: this.DOM.view.querySelector('#name'),
      email: this.DOM.view.querySelector('#email'),
      phone: this.DOM.view.querySelector('#phone'),
      subject: this.DOM.view.querySelector('#subject'),
      message: this.DOM.view.querySelector('#message'),
    };

    this.initformContact();

  }


  initformContact() {

    const url = this.DOM.formContact.dataset.url;

    this.DOM.formContact.addEventListener("submit", (e) => {

      e.preventDefault();

      document.body.style.cursor = "wait";
      const formData = new FormData();

      Object.keys(this.DOM.fields).forEach((key) => {
        const field = this.DOM.fields[key];
        formData.append(key, field.value);
      });


      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          document.body.style.cursor = "default";
          if (xhr.status === 200) {
            this.DOM.formMessage.classList.remove("error");
            this.DOM.formMessage.innerText = xhr.responseText;
            this.DOM.formContact.reset();
          } else {
            this.DOM.formMessage.classList.add("error");
            this.DOM.formMessage.innerText = `Une erreur est survenue: (${xhr.responseText})`;
          }
        }
      };
      xhr.send(formData);
    })
  }
}
