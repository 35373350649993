import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";

export default class PageVetoDashboard extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageVetoDashboard');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.PictureInput = this.DOM.view.querySelector('#PictureInput');
    this.DOM.UserPicture = this.DOM.view.querySelector('#UserPicture');
    this.DOM.UserPictureError = this.DOM.view.querySelector('#UserPictureError');
    this.PostPictureUrl = this.DOM.PictureInput.dataset.url;

    this.DOM.PictureInput.addEventListener("change", ()=> this.onPictureChange());

  }


  onPictureChange(){
    if (!this.DOM.PictureInput.files.length) return;
    document.body.style.cursor = "wait";
    this.DOM.UserPictureError.innerText = "";

    const file = this.DOM.PictureInput.files[0];

    const formData = new FormData;
    formData.append("image", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", this.PostPictureUrl, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          this.DOM.UserPicture.src = xhr.responseText;
        } else {
          this.DOM.UserPictureError.innerText = xhr.responseText;
        }
        document.body.style.cursor = "default";
      }
    };
    xhr.send(formData);
  }

}
