import Highway from '@dogstudio/highway';
import Swiper from "swiper";

export default class SingleSante extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Single News');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.NewsSwiper = this.DOM.view.querySelector('.News__swiper');
    
    if (this.DOM.NewsSwiper) this.initNews();

  }
  
  onLeaveCompleted() {
    
    if (this.NewsSwiper) this.NewsSwiper.destroy();
    
  }
  
  initNews() {
    
    this.NewsSwiper = new Swiper(this.DOM.NewsSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        navigation: {
          nextEl: this.DOM.NewsSwiper.querySelector('.swiper-next'),
          prevEl: this.DOM.NewsSwiper.querySelector('.swiper-prev'),
        },
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
    });
    
  }
}
