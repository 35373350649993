import Highway from '@dogstudio/highway';
import Plyr from 'plyr';
import Swiper from "swiper";

export default class SingleNews extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Single News');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.NewsPlayers = this.DOM.view.querySelectorAll('.player');
    this.plrys = [];
    this.DOM.NewsSwiper = this.DOM.view.querySelector('.News__swiper');
    this.DOM.favoriteButton = this.DOM.view.querySelector('#AddFavoris');
    this.DOM.favoriteText = this.DOM.view.querySelector('#AddFavorisText');

    this.DOM.facebookShareButton = this.DOM.view.querySelectorAll('.fb_share');
    this.DOM.linkedinShareButton = this.DOM.view.querySelectorAll('.lk_share');

    if (this.DOM.NewsPlayers) this.initPlayers();
    if (this.DOM.NewsSwiper) this.initNews();

    if (this.DOM.favoriteButton) this.initFavoriteAction();
    this.initShare();

  }

  onLeaveCompleted() {

    if (this.DOM.NewsPlayers) {
      this.plrys.forEach((player) => {
        player.destroy();
      })
    }

    if (this.NewsSwiper) this.NewsSwiper.destroy();

  }

  initPlayers() {

    this.DOM.NewsPlayers.forEach((player) => {
      const newsPlayer = new Plyr(player);
      this.plrys.push(newsPlayer);

    })

  }

  initNews() {

    this.NewsSwiper = new Swiper(this.DOM.NewsSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        navigation: {
          nextEl: this.DOM.NewsSwiper.querySelector('.swiper-next'),
          prevEl: this.DOM.NewsSwiper.querySelector('.swiper-prev'),
        },
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
    });

  }

  initFavoriteAction() {

    this.favoriteUrl = this.DOM.favoriteButton.dataset.url;
    this.news_id = this.DOM.favoriteButton.dataset.newsid;

    this.DOM.favoriteButton.addEventListener("click", (e) => {
      e.preventDefault();
      document.body.style.cursor = "wait";

      const formData = new FormData;
      formData.append("id", this.news_id)

      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.favoriteUrl, true);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.DOM.favoriteText.innerText = xhr.responseText;
          } else {
            alert("Une erreur est survenue");
          }
          document.body.style.cursor = "default";
        }
      };
      xhr.send(formData)
    })
  }

  initShare() {
    this.DOM.facebookShareButton.forEach((btn) => {
      btn.addEventListener("click", (e)  => {
          e.preventDefault();
          var fbpopup = window.open("https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(btn.dataset.url), "", "width=400, height=600, scrollbars=no");
          return false;
        })
    })

    this.DOM.linkedinShareButton.forEach((btn) => {
      btn.addEventListener("click", (e)  => {
        e.preventDefault();
        var lkpopup = window.open("http://www.linkedin.com/shareArticle?mini=true&url="+encodeURIComponent(btn.dataset.url), "", 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
        return false;
      })
    })
  }
}
