import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";

export default class PageVetoRessources extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageVetoRessources');
    this.DOM = {view: this.wrap.lastElementChild};
    this.DOM.searchInput = this.DOM.view.querySelector("#searchDocInput");

    this.DOM.modal = this.DOM.view.querySelector("#DocCommandeModal");
    this.DOM.modalDocForm = this.DOM.view.querySelector("#modalDocForm");
    this.DOM.modalDocTitle = this.DOM.view.querySelector("#modalDocTitle");
    this.DOM.modalDocMessage = this.DOM.view.querySelector("#modalDocMessage");
    this.modalDocUrl = this.DOM.modalDocForm.dataset.url;

    const inputs = ["civility", "firstname", "name", "email", "phone", "address", "zipcode", "city", "country", "qtty"];
    this.DOM.formInputs = inputs.map((item) => this.DOM.view.querySelector(`#doc_${item}`));

    this.initModals();
    this.initSearch();

  }

  initModals() {
    const modalDocTitle = this.DOM.modalDocTitle;
    $('[data-modal]').magnificPopup({
      callbacks: {
        beforeOpen: function(e) {
          const clicked = this.st.el;
          console.log(clicked);
          modalDocTitle.innerText = clicked[0].dataset.title;
        }
      },
      removalDelay: 400
    });

    this.DOM.modalDocForm.addEventListener("submit", (e) => {
      e.preventDefault();
      document.body.style.cursor = "wait";

      const formData = new FormData();
      this.DOM.formInputs.map((item)  => {
        const name = item.id.replace("doc_", "");
        formData.append(name, item.value);
      });
      formData.append("title", modalDocTitle.innerText);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.modalDocUrl, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.DOM.modalDocMessage.classList.remove("error");
            this.DOM.modalDocMessage.innerText = xhr.responseText;
            this.DOM.modalDocForm.reset()
            setTimeout(()=> $(this.DOM.modal).magnificPopup('close'), 3000);
          } else {
            this.DOM.modalDocMessage.classList.add("error");
            this.DOM.modalDocMessage.innerText = xhr.responseText
          }
          document.body.style.cursor = "default";

        }
      };
      xhr.send(formData);
    })

  }

  initSearch() {
    let timer = null;
    this.DOM.searchInput.addEventListener("keyup", (e) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.runSearch(this.DOM.searchInput.value)
      }, 100)
    })
  }

  runSearch(term) {
    const listCategory = this.DOM.view.querySelectorAll("section.Documents");
    for (let categoryContainer of listCategory){
      let categoryHasVisibleDocs = false;
      const listDocs = categoryContainer.querySelectorAll(".Document");
      const categoryTitle = categoryContainer.querySelector("h2");
      for (let documentContainer of listDocs) {
        if (term === "" || documentContainer.dataset.doctitle.toLowerCase().includes(term.toLowerCase()) || documentContainer.dataset.docdesc.toLowerCase().includes(term.toLowerCase())) {
          documentContainer.style.display = "block";
          categoryHasVisibleDocs = true;
        } else {
          documentContainer.style.display = "none";
        }
      }
      categoryTitle.style.display = categoryHasVisibleDocs ? "block" : "none";

    }
  }
}
