import { TweenLite, Power3 } from 'gsap/all';
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin';

const scrollTo = ScrollToPlugin;

export default class Header {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.body = document.body;
    this.DOM.brand = this.DOM.el.querySelector('.brand');
    this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container');
    this.DOM.ADPbtn = this.DOM.menuContainer.querySelector('.ADP__btn');
    this.menuOpen = false;

    this.DOM.headerSearch = this.DOM.el.querySelector('#searchGlobal');

    this.init();
    this.initSearch();

  }

  init() {

    if (window.DEVMODE) console.log('initHeader');

    // Toggle
    this.DOM.toggle.addEventListener('click', (e) => {

      e.preventDefault();
      this.menuOpen === true ? this.close() : this.open();

    });

    this.DOM.ADPbtn.addEventListener('click', (e) => {
      e.preventDefault();
      TweenLite.to(window, 1.2, { scrollTo: { y: document.documentElement.scrollHeight - window.innerHeight, autoKill: false }, ease: Power3.easeInOut });
    })

  }

  open() {

    return new Promise((resolve, reject) => {

      // Console.log('Open Menu');
      this.menuOpen = true;
      this.DOM.body.classList.add('showMenu');
      this.DOM.body.style.overflow = 'hidden';

    });

  }

  close() {

    return new Promise((resolve, reject) => {

      // Console.log('Close Menu');
      this.menuOpen = false;
      this.DOM.body.classList.remove('showMenu');
      this.DOM.body.style.overflow = '';

    });

  }

  initSearch() {
    this.DOM.headerSearch.addEventListener('submit', (e) => {
      e.preventDefault();
      window.H.redirect(`${e.target.getAttribute('action')}?term=${e.target[0].value}`);
    })
  }

}
