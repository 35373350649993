import {getPosition}  from '../utils.js';

export default class pageAnchors {

  constructor(container) {

    this.DOM = {
      container: container
    };

    this.DOM.AnchorsLinks = this.DOM.container.querySelectorAll('.anchors__item');
    if (this.DOM.AnchorsLinks.length === 0) return;

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('Init Anchors');

    this.DOM.AnchorsLinks.forEach((item) => {

      item.addEventListener('click', (e) => {

        e.preventDefault();

        // anchors active
        if (this.DOM.container.querySelector('.is-active')) this.DOM.container.querySelector('.is-active').classList.remove('is-active');

        const targetAnchor = e.currentTarget.getAttribute('href');
        e.currentTarget.classList.add('is-active');
        let targetY = getPosition(document.querySelector(targetAnchor)).y;
        TweenLite.to(window, 1.2, { scrollTo: { y: targetY, autoKill: false }, ease: Power3.easeInOut });

      });
    });

  }
}
