// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import DefaultPageTransition from '../pages/page-transition.js';
import PageHome from '../pages/page-home.js';
import SingleProduct from '../pages/single-product.js';
import SingleTeam from '../pages/single-team.js';
import SingleNews from '../pages/single-news.js';
import SingleOffre from '../pages/single-offre';
import PageExperts from '../pages/page-experts.js';
import PageSearch from '../pages/page-search.js';
import SingleSante from '../pages/single-sante.js';
import PageRecrutment from '../pages/page-recrutment.js';
import PageCavalierDashboard from '../pages/page-cavalier.js';
import PageLogin from '../pages/page-login.js';
import PageIdentity from '../pages/page-identity.js';
import PageVetoRessources from '../pages/veto-ressources.js';
import PageVetoTolbox from '../pages/veto-toolbox.js';
import PageNews from "../pages/page-news";
import PageContact from "../pages/page-contact";
import PageVetoDashboard from "../pages/page-veto-dashboard";
import PageVetoLogin from "../pages/veto-login";

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

  }


  createHighway() {

    window.H = new Highway.Core({
      renderers: {
        pageHome: PageHome,
        singleProduct: SingleProduct,
        singleTeam: SingleTeam,
        singleNews: SingleNews,
        singleOffre: SingleOffre,
        pageContact: PageContact,
        pageExperts: PageExperts,
        pageSearch: PageSearch,
        pageNews: PageNews,
        singleSante: SingleSante,
        pageRecrutement: PageRecrutment,
        pageCavalierLogin: PageLogin,
        pageIdentity: PageIdentity,
        pageCavalierDashboard: PageCavalierDashboard,
        pageVetoRessources: PageVetoRessources,
        pageVetoToolbox: PageVetoTolbox,
        pageVetoDashboard: PageVetoDashboard,
        pageVetoLogin: PageVetoLogin,
        pagename: DefaultPageRenderer
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // RemovePrllx
      this.view.prllx.destroy();

    });

    window.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is-active');
        if (link.href === location.href) {
          link.classList.add('is-active');
        }
      });

      this.initView(to.view);

    });

    window.H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      // Analytics
      /* if (typeof gtag !== 'undefined') {
         gtag('config', 'UA-XXXXXXXXX-X', {
           'page_path': location.pathname,
           'page_title': to.page.title,
           'page_location': location.href
         });
      } */

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // Check Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Prllx / InView
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);

  }

}
