import FastClick from 'modern-fastclick';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Routes from './modules/routes.js';

import { TimelineLite, TweenLite, CSSPlugin, Power3, Power0 } from 'gsap';

window.DEVMODE = DEVMODE === 'development';
window.Sniff;
window.H = null;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Intro = this.DOM.Body.querySelector('#Intro');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    this.DOM.NewsletterForm = this.DOM.Body.querySelector("#NewsletterForm");
    this.DOM.NewsletterFormInput = this.DOM.Body.querySelector("#NewsletterFormInput");
    this.DOM.NewsletterFormFeedback = this.DOM.Body.querySelector("#NewsletterFormFeedback");

    this.DOM.SelectProduct = this.DOM.Body.querySelector("#AdpProduct");
    this.DOM.SelectLotNumber = this.DOM.Body.querySelector("#AdpLotNumber");
    this.DOM.DownloadButton = this.DOM.Body.querySelector("#DownloadButton");


    // Signature Wokine
    // console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    // console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

    this.init();
    this.initNewsletter();
    this.initAdp();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('🔥 DEVMODE BIATCH 🔥');    

    // Sniffer
    window.Sniff = newSniff();


    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is-first');
      this.DOM.Body.classList.remove('is-loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }


    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';


    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header);


    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      // Intro
       this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }

  intro() {

    this.DOM.Body.classList.remove('is-first');
    this.DOM.Body.classList.remove('is-loading');
    this.DOM.Body.classList.remove('is-animating');
    const intro = new TimelineLite({
      paused: true,
      onStart: () => {
        this.DOM.Body.classList.remove('is-first');
        this.DOM.Body.classList.remove('is-loading');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is-animating');
      },
      delay: 0.4
    });

    intro.fromTo(this.DOM.Intro.querySelector('.brand'), 1.2, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: Power3.easeOut, clearProps: 'all' }, 0.6);
    intro.fromTo(this.DOM.Intro.querySelector('.visual__container'), 1.2, { opacity: 0, scale:1.1 }, { opacity:1, scale:1, ease: Power3.easeOut }, 0);
    intro.fromTo(this.DOM.Intro.querySelector('.visual__container .visual'), 1.2, { scale:2 }, { scale:1.6, ease: Power3.easeOut }, 0);
    intro.fromTo(this.DOM.Intro.querySelector('.visual__container'), 1.6, { width: "50%", height: "50%" }, { width: "100%", height: '100%', ease: Power3.easeInOut }, 1.6);
    intro.fromTo(this.DOM.Intro.querySelector('.visual__container .visual'), 1.6, { scale: 1.6 }, { scale: 1, ease: Power3.easeInOut }, 1.6);
    intro.fromTo(this.DOM.Intro.querySelector('.inner'), 1, { y: 0 }, { y: '75%', ease: Power3.easeInOut, clearProps: 'all' }, 2.6);
    intro.fromTo(this.DOM.Intro.querySelector('.visual__container'), 1, { y: 0 }, { y: '90%', ease: Power3.easeInOut, clearProps: 'all' }, 2.6);
    intro.fromTo(this.DOM.Intro, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'all' }, 2.6);
    intro.fromTo(document.querySelector('.App__container'), 1, { y: 80 }, { y: 0, ease: Power3.easeInOut, clearProps: 'all' }, 2.6);

    intro.play();

  }

  initNewsletter() {
    const url = this.DOM.NewsletterForm.dataset.url;
    this.DOM.NewsletterForm.addEventListener("submit",(e)=> {
      e.preventDefault();
      const email = this.DOM.NewsletterFormInput.value;
      if (email === "") return;

      document.body.style.cursor = 'wait';
      this.DOM.NewsletterFormFeedback.innerHTML = "";

      const formData = new FormData;
      formData.append("email", email);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.DOM.NewsletterFormFeedback.classList.remove("error");
          } else {
            this.DOM.NewsletterFormFeedback.classList.add("error");
          }
          this.DOM.NewsletterFormFeedback.innerHTML = `<p>${xhr.responseText}</p>`;
          document.body.style.cursor = 'default';
        }
      }
      xhr.send(formData);
    })
  }


  initAdp() {
    this.DOM.SelectProduct.addEventListener("change", (e)  => this.onAdbProductChange());
    this.DOM.SelectLotNumber.addEventListener("change", (e)  => this.onAdbLotChange());
    this.DOM.DownloadButton.addEventListener("click", (e)  => this.onAdbDownload(e));
    this.onAdbProductChange()
    this.onAdbLotChange()
  }

  onAdbProductChange() {
    this.DOM.SelectLotNumber.disabled = true;

    if  (this.DOM.SelectProduct.value === ""){
      return;
    }
    document.body.style.cursor = "wait";

    const xhr = new XMLHttpRequest();
    xhr.open("GET", this.DOM.SelectProduct.dataset.url+"?term_id="+this.DOM.SelectProduct.value, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const json = JSON.parse(xhr.responseText);
          if (json.length === 0) return;

          let options = this.DOM.SelectLotNumber.querySelectorAll("option");
          for (let option of options){
            if (option.value !== "") this.DOM.SelectLotNumber.removeChild(option);
          }

          for (let item of json){
            const opt = document.createElement("option");
            opt.innerText = item.title;
            opt.dataset.download = item.download_link;
            this.DOM.SelectLotNumber.append(opt);
          }
          this.DOM.SelectLotNumber.disabled = false;

        }
      }
      document.body.style.cursor = "default";
    };
    xhr.send(null);

  }

  onAdbLotChange() {
    const selected = this.DOM.SelectLotNumber.selectedIndex;
    if (selected === 0) {
      this.DOM.DownloadButton.disabled = true ;
      return;
    }
    this.DOM.DownloadButton.disabled = false ;
  }

  onAdbDownload(e){
    e.preventDefault()
    const selected = this.DOM.SelectLotNumber.selectedIndex;
    const url = this.DOM.SelectLotNumber.options[selected].dataset.download;
    const title = this.DOM.SelectLotNumber.options[selected].value;
    window.open(url, title);
  }
}

new App();
