import Highway from '@dogstudio/highway';
import Swiper from "swiper";

export default class SingleTeam extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Single Team');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.ProductsSwiper = this.DOM.view.querySelector('.Products__swiper');
    
    if (this.DOM.ProductsSwiper) this.initRelatedProducts();

  }
  
  onLeaveCompleted() {
    
    if (this.RelatedProductsSwiper) this.RelatedProductsSwiper.destroy();
    
  }
  
  initRelatedProducts() {
    
    this.RelatedProductsSwiper = new Swiper(this.DOM.ProductsSwiper, {
      spaceBetween: 40,
      slidesPerView: 3,
      navigation: {
        nextEl: this.DOM.ProductsSwiper.querySelector('.swiper-next'),
        prevEl: this.DOM.ProductsSwiper.querySelector('.swiper-prev'),
      },
      breakpoints: {
        992: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        1199: {
          slidesPerView: 2,
          spaceBetween: 20
        }
      }
    });
    
  }

}
