import Highway from '@dogstudio/highway';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
import { english } from "flatpickr/dist/l10n/default.js"

export default class PageLogin extends Highway.Renderer {

  onEnter() {
    if (window.DEVMODE) console.log('PageLogin');
    this.DOM = { view: this.wrap.lastElementChild };

    flatpickr("#subscribeBirthdate", {
      "locale": document.documentElement.lang === 'en-US' ? english : French,
    });
  }
}
