import Highway from '@dogstudio/highway';

export default class PageNews extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Page News');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.searchForm = this.DOM.view.querySelector('#searchForm');
    this.DOM.searchInput = this.DOM.view.querySelector('#searchInput');
    this.DOM.termsCb = this.DOM.view.querySelectorAll('.filter__news');
    this.DOM.expertsSelect = this.DOM.view.querySelector('#expertSelect');

    this.DOM.loadMore = this.DOM.view.querySelector('#loadMore');
    this.DOM.newsContainer = this.DOM.view.querySelector('#newsContainer');

    this.page = 1;

    this.DOM.searchForm.addEventListener("submit", (e) => {
       e.preventDefault();
       this.page=1;
       this.runSearch();
    })

    this.DOM.expertsSelect.addEventListener("change", (e) => {
      this.page=1;
      this.runSearch();
    });

    var timer;
    this.DOM.searchInput.addEventListener("keyup", (e) => {
      e.preventDefault();
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.page=1;
        this.runSearch();
      }, 300);
    })

    for (let cb of this.DOM.termsCb){
      cb.addEventListener("change", (e) => {
        e.preventDefault();
        this.page=1;
        this.runSearch();
      })
    }

    this.DOM.loadMore.addEventListener("click", (e)  => {
      e.preventDefault();
      e.stopPropagation()
      this.page++;
      this.runSearch();

    })

    this.runSearch()
  }


  getParameters(){
    var term = "all";
    for (let cb of this.DOM.termsCb){
      if (cb.checked) term = cb.value
    }

    return {
      "s": this.DOM.searchInput.value,
      "expert": this.DOM.expertsSelect.value,
      "term": term,
      "page": this.page,
    }
  }


  runSearch() {
    document.body.style.cursor = "wait";
    const xhr = new XMLHttpRequest();
    xhr.open("POST", this.DOM.searchForm.dataset.url+"?search=true", true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);

          if (this.page === 1) this.DOM.newsContainer.innerHTML = data.html;
          else this.DOM.newsContainer.innerHTML += data.html;

          this.DOM.loadMore.style.display = (data.left == 0) ?  "none" : "block";
        } else {
          console.log(xhr.responseText);
        }
      }
      document.body.style.cursor = "default";
    };
    xhr.send(JSON.stringify(this.getParameters()));
  }
}
