import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";

export default class PageExperts extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageExperts');
    this.DOM = { view: this.wrap.lastElementChild };
    
    this.initModals();

  }
  
  initModals() {
    
    $('[data-modal]').magnificPopup({
      type: 'ajax',
      removalDelay:400,
      callbacks: {
        parseAjax: function (mfpResponse) {
          mfpResponse.data = $($.parseHTML(mfpResponse.data)).find('.Expert__card');
        }
      }
    });
    
  }
}
