import Highway from '@dogstudio/highway';
import Swiper from "swiper";

export default class PageHome extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Homepage');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.blocksSwiper = this.DOM.view.querySelector('.Blocks__swiper');
    this.DOM.bestSellerSwiper = this.DOM.view.querySelector('.BestSeller__swiper');
    this.DOM.NewsSwiper = this.DOM.view.querySelector('.News__swiper');

    if (this.DOM.blocksSwiper) this.initBlocks();
    if (this.DOM.bestSellerSwiper) this.initBestSeller();
    if (this.DOM.NewsSwiper) this.initNews();

  }

  onLeaveCompleted() {

    if (this.blocksSwiper) this.blocksSwiper.destroy();
    if (this.bestSellerSwiper) this.bestSellerSwiper.destroy();
    if (this.NewsSwiper) this.NewsSwiper.destroy();

  }

  initBlocks() {

    this.blocksSwiper = new Swiper(this.DOM.blocksSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          767: {
            slidesPerView: "auto",
            spaceBetween: 15,
          },
          991: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        }
    });

  }

  initBestSeller() {

    this.bestSellerSwiper = new Swiper(this.DOM.bestSellerSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        navigation: {
          nextEl: this.DOM.bestSellerSwiper.querySelector('.swiper-next'),
          prevEl: this.DOM.bestSellerSwiper.querySelector('.swiper-prev'),
        },
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
    });

  }

  initNews() {

    this.NewsSwiper = new Swiper(this.DOM.NewsSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        navigation: {
          nextEl: this.DOM.NewsSwiper.querySelector('.swiper-next'),
          prevEl: this.DOM.NewsSwiper.querySelector('.swiper-prev'),
        },
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
    });

  }

}
