import Highway from '@dogstudio/highway';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class PageIdentity extends Highway.Renderer {

  onEnter() {
    if (window.DEVMODE) console.log('PageIdentity');
    this.DOM = { view: this.wrap.lastElementChild };

    flatpickr("#birthday", {
      "locale": French,
      dateFormat: "d/m/Y",
    });

  }
}
