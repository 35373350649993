import Highway from '@dogstudio/highway';
import {getPosition}  from '../utils.js';

export default class PageRecrutment extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageRecrutment');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.subMenu = this.DOM.view.querySelector('.Page__submenu');
    this.DOM.AnchorsLinks = this.DOM.view.querySelectorAll('.is--anchor');

    this.initAnchors();

  }

  initAnchors() {

    this.DOM.AnchorsLinks.forEach((item) => {
      item.addEventListener('click', (e) => {

        e.preventDefault();

        // anchors active
        if (this.DOM.subMenu.querySelector('.is--active')) this.DOM.subMenu.querySelector('.is--active').classList.remove('is--active');

        const targetAnchor = e.currentTarget.getAttribute('href');
        e.currentTarget.classList.add('is--active');
        let targetY = getPosition(document.querySelector(targetAnchor)).y;
        if (document.querySelector(targetAnchor).dataset.padding) targetY = targetY - parseFloat(document.querySelector(targetAnchor).dataset.padding);

        TweenLite.to(window, 1.2, { scrollTo: { y: targetY, autoKill: false }, ease: Power3.easeInOut });

      });
    })

  }
}
