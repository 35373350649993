import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";

export default class PageVetoToolbox extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageVetoToolbox');
    this.DOM = {view: this.wrap.lastElementChild};

    this.DOM.modal = this.DOM.view.querySelector("#ToolboxCommandeModal");
    this.DOM.modalToolboxForm = this.DOM.view.querySelector("#modalToolboxForm");
    this.DOM.modalToolboxTitle = this.DOM.view.querySelector("#modalToolboxTitle");
    this.DOM.modalToolboxMessage = this.DOM.view.querySelector("#modalToolboxMessage");
    this.modalToolboxUrl = this.DOM.modalToolboxForm.dataset.url;

    const inputs = ["civility", "firstname", "name", "email", "phone", "address", "zipcode", "city", "country", "qtty"];
    this.DOM.formInputs = inputs.map((item) => this.DOM.view.querySelector(`#tb_${item}`));

    this.initModals();

  }

  initModals() {
    const modalToolboxTitle = this.DOM.modalToolboxTitle;
    $('[data-modal]').magnificPopup({
      callbacks: {
        beforeOpen: function(e) {
          const clicked = this.st.el;
          modalToolboxTitle.innerText = clicked[0].dataset.title;
        }
      },
      removalDelay: 400
    });

    this.DOM.modalToolboxForm.addEventListener("submit", (e) => {
      e.preventDefault();
      document.body.style.cursor = "wait";

      const formData = new FormData();
      this.DOM.formInputs.map((item)  => {
        const name = item.id.replace("tb_", "");
        formData.append(name, item.value);
      });
      formData.append("title", modalToolboxTitle.innerText);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.modalToolboxUrl, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.DOM.modalToolboxMessage.classList.remove("error");
            this.DOM.modalToolboxMessage.innerText = xhr.responseText;
            this.DOM.modalToolboxForm.reset()
            setTimeout(()=> $(this.DOM.modal).magnificPopup('close'), 3000);
          } else {
            this.DOM.modalToolboxMessage.classList.add("error");
            this.DOM.modalToolboxMessage.innerText = xhr.responseText
          }
          document.body.style.cursor = "default";

        }
      };
      xhr.send(formData);
    })

  }
}
