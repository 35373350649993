import Highway from '@dogstudio/highway';

export default class PageSearch extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('pageSearch');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.ResultsNavTabs = this.DOM.view.querySelector('.Nav__tabs');
    this.DOM.searchForm = this.DOM.view.querySelector('.Search__pageform');
    this.DOM.searchBar = this.DOM.view.querySelector('#searchBar');

    this.DOM.resultsProducts = this.DOM.view.querySelector('#Products');
    this.DOM.countProducts = this.DOM.view.querySelector('#countProducts');

    this.DOM.resultsNews = this.DOM.view.querySelector('#News');
    this.DOM.countNews = this.DOM.view.querySelector('#countNews');

    this.DOM.resultsPathologies = this.DOM.view.querySelector('#Pathologies');
    this.DOM.countPathologies = this.DOM.view.querySelector('#countPathologies');

    if (this.DOM.ResultsNavTabs) this.initTabs();

    this.initSearch();

  }

  initTabs() {

    const TabLinks = this.DOM.ResultsNavTabs.querySelectorAll('.Tab__link');
    TabLinks.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();

        // Remove Prev Tabs
        const prevActives = this.DOM.view.querySelectorAll('.is--active');
        prevActives.forEach((item) => {
          item.classList.remove('is--active');
        });

        // Show Active Tabs
        const targetAnchor = e.currentTarget.getAttribute('href');
        const targetTab = this.DOM.view.querySelector(targetAnchor);

        e.currentTarget.classList.add('is--active');
        targetTab.classList.add('is--active');
      });
    });

  }

  initSearch(){
    let timerSearch = null;
    this.DOM.searchBar.addEventListener("keyup", () => {
      clearTimeout(timerSearch);
      timerSearch = setTimeout(() => {
        this.runSearch()
      }, 250);
    });
    this.runSearch();
  }

  runSearch(){

    this.DOM.searchForm.classList.add('is--loading');

    const xhr = new XMLHttpRequest();
    const s = this.DOM.searchBar.value;
    if (!s || s === "") return;
    document.body.style.cursor="wait";
    xhr.open("GET", this.DOM.searchBar.dataset.url+"?s="+encodeURIComponent(s), true);

    xhr.onreadystatechange = () => {

      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const json = JSON.parse(xhr.responseText);

          this.DOM.countProducts.innerText = json.products.count;
          this.DOM.countNews.innerText = json.news.count;
          this.DOM.countPathologies.innerText = json.pathologies.count;

          this.DOM.resultsProducts.innerHTML = json.products.html;
          this.DOM.resultsNews.innerHTML = json.news.html;
          this.DOM.resultsPathologies.innerHTML = json.pathologies.html;

        }
        document.body.style.cursor="default";
      }

      this.DOM.searchForm.classList.remove('is--loading');
    };
    xhr.send(null);

  }


}
