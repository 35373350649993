import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class PageVetoLogin extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageVetoLogin');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.FormAccountRequest = this.DOM.view.querySelector('#VetoInscriptionForm');
    this.DOM.FormAccountRequestMessage = this.DOM.view.querySelector('#HorseEditFormMessage');

    this.UrlAccountRequest = this.DOM.FormAccountRequest.dataset.url;

    this.DOM.SelectProfession = this.DOM.FormAccountRequest.querySelector("#profession");

    this.DOM.OrderNumField = this.DOM.FormAccountRequest.querySelector("#order_num");
    this.DOM.SchoolField = this.DOM.FormAccountRequest.querySelector("#school");


    this.StudentValue = this.DOM.FormAccountRequest.querySelector("#StudentValue").value;
    const fields = ['name', 'firstname', 'birthdate', 'zipcode', 'country', 'city', 'profession', 'school', 'order_num', 'company', 'phone', 'email', 'newsletter', 'newsletter_pro'];

    this.accountRequestFields = {};
    fields.forEach((item) => {
      this.accountRequestFields[item] = this.DOM.FormAccountRequest.querySelector('#'+item);
    });

    this.initModals();
    this.initFormAccountRequest();

  }

  initModals() {

    const DOM = this.DOM;

    $('[data-modal]').magnificPopup({
      removalDelay: 400,
      callbacks: {
        //beforeOpen: function () {
        open: function () {
          console.log('open modal')

          DOM.FormAccountRequest.reset();
          flatpickr("#birthdate", {
            "locale": French,
            dateFormat: "d/m/Y",
          });
        }
      }
    });

  }

  initFormAccountRequest() {

    this.DOM.SelectProfession.addEventListener("change", (e)  => this.onProfesionChange());
    this.onProfesionChange();


    this.DOM.FormAccountRequest.addEventListener("submit", (e)  => {
      e.preventDefault();
      document.body.style.cursor = "wait";
      this.DOM.FormAccountRequestMessage.innerText= "";

      const formData = new FormData;
      formData.append("firstname", this.accountRequestFields.firstname.value);
      formData.append("name", this.accountRequestFields.name.value);
      formData.append("birthdate", this.accountRequestFields.birthdate.value);
      formData.append("city", this.accountRequestFields.city.value);
      formData.append("zipcode", this.accountRequestFields.zipcode.value);
      formData.append("country", this.accountRequestFields.country.value);

      const professionValue = this.accountRequestFields.profession.value;
      formData.append("profession", professionValue);
      if (professionValue == this.StudentValue) formData.append("school", this.accountRequestFields.school.value)
      else formData.append("order_num", this.accountRequestFields.order_num.value);

      formData.append("company", this.accountRequestFields.company.value);

      const isHorseman = document.querySelector('input[name="cavalier"]:checked').value;
      formData.append("is_horseman", isHorseman)

      formData.append("phone", this.accountRequestFields.phone.value);
      formData.append("email", this.accountRequestFields.email.value);
      if (this.accountRequestFields.newsletter.checked){
        formData.append("newsletter", this.accountRequestFields.newsletter.checked);
      }
      if (this.accountRequestFields.newsletter_pro.checked){
        formData.append("newsletter_pro", this.accountRequestFields.newsletter_pro.checked);
      }

      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.UrlAccountRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          document.body.style.cursor = "default";
          this.DOM.FormAccountRequestMessage.innerText=xhr.responseText;

          if (xhr.status === 200) {
            this.DOM.FormAccountRequestMessage.classList.remove("error")
            setTimeout(()=>{
              $('#VetoInscriptionModal').magnificPopup('close');
              this.DOM.FormAccountRequest.reset();
            }, 2000);
          } else {
            this.DOM.FormAccountRequestMessage.classList.add("error")
          }
        }
      }
      xhr.send(formData);
    })
  }

  onProfesionChange(){
    const isStudent= this.DOM.SelectProfession.value == this.StudentValue;
    console.log(isStudent);
    this.DOM.OrderNumField.required = !isStudent;
    this.DOM.OrderNumField.disabled = isStudent;

    this.DOM.SchoolField.required = isStudent;
    this.DOM.SchoolField.disabled = !isStudent;
  }
}
