// Highway
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);
    from.parentNode.removeChild(from);

    // Timeline animation page enter
    const pageIn = new TimelineLite({
      paused: true,
      onStart: () => {
        document.body.classList.remove('is-loading');
      },
      onComplete: () => {
        document.body.classList.remove('is-animating');
        done();
      }
    });

    pageIn.fromTo(to, 1, { y: 20, opacity: 0 }, { y: 0, opacity: 1, ease: Power3.easeInOut, clearProps: 'all' }, 0);
    /*pageIn.fromTo(Loader.querySelector('.inner'), 1, { y: 0, scale:1 }, { y: '80%', scale:1.2, ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.fromTo(Loader, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'all' }, 0);*/
    pageIn.timeScale(1).play();

  }

  out({ from, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Timeline animation page leave
    const pageOut = new TimelineLite({
      paused: true,
      onStart: () => {
        document.body.classList.add('is-loading');
        document.body.classList.add('is-animating');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.fromTo(from, 1, { y: 0, opacity: 1}, { y: -20, opacity: 0, ease: Power3.easeInOut }, 0);
    /*pageOut.fromTo(Loader.querySelector('.inner'), 1, { y: '-80%', scale:1.2 }, { y: '0%', scale:1, ease: Power3.easeInOut }, 0);
    pageOut.fromTo(Loader, 1, { y: '100%' }, { y: '0%', ease: Power3.easeInOut }, 0);*/

    pageOut.timeScale(1).play();

  }

}
