import Highway from '@dogstudio/highway';
import Chart from 'chart.js';
import Swiper from "swiper";
import "magnific-popup";
import $ from "jquery";

export default class SingleProduct extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Product');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.ProductGallery = this.DOM.view.querySelector('.Product__gallery');
    this.DOM.ProductGalleryThumbs = this.DOM.view.querySelector('.Product__gallery__thumb');
    this.DOM.ProductCharts = this.DOM.view.querySelectorAll('.Chart');
    this.DOM.ProductTabs = this.DOM.view.querySelector('.Tabs');
    this.DOM.ProductsSwiper = this.DOM.view.querySelector('.Products__swiper');
    this.DOM.NewsSwiper = this.DOM.view.querySelector('.News__swiper');
    this.DOM.SelectConditionnement = $('.Product__conditionnement .radio');
    this.Charts = [];

    if (this.DOM.ProductGallery) this.iniGallery();
    
    if (this.DOM.SelectConditionnement) this.selectCond();
    // if (this.DOM.ProductCharts) this.initCharts();

    if (this.DOM.ProductTabs) this.initTabs();
    if (this.DOM.ProductsSwiper) this.initRelatedProducts();
    if (this.DOM.NewsSwiper) this.initNews();
    this.initModals();
  }

  onLeaveCompleted() {

    if (this.ProductGalleryThumsSwiper) this.ProductGalleryThumsSwiper.destroy();
    if (this.ProductGallerySwiper) this.ProductGallerySwiper.destroy();
    if (this.RelatedProductsSwiper) this.RelatedProductsSwiper.destroy();
    if (this.NewsSwiper) this.NewsSwiper.destroy();
    if (this.DOM.ProductCharts) {
      this.Charts.forEach((chart) => {
        chart.destroy();
      })
    }

  }

  iniGallery() {

    this.ProductGalleryThumsSwiper = new Swiper(this.DOM.ProductGalleryThumbs, {
        spaceBetween: 10,
        slidesPerView: 4,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: this.DOM.ProductGalleryThumbs.querySelector('.swiper-buttons-th .next'),
          prevEl: this.DOM.ProductGalleryThumbs.querySelector('.swiper-buttons-th .prev')
        }
    });

    this.ProductGallerySwiper = new Swiper(this.DOM.ProductGallery, {
      spaceBetween: 0,
      virtualTranslate:true,
      thumbs: {
        swiper: this.ProductGalleryThumsSwiper
      },
      navigation: {
        nextEl: this.DOM.ProductGallery.querySelector('.swiper-buttons .next'),
        prevEl: this.DOM.ProductGallery.querySelector('.swiper-buttons .prev')
      }
    });
  }

  selectCond() {

    this.DOM.SelectConditionnement.on("click", function(item) {

      if($(this).data("imgid")){
        var idSelector = $(this).data("imgid");
        var index;

        $(".limselectorimg").each(function(i) {
          if ($(this).attr("id") === idSelector) {
            index = i;
            return false; // Sortir de la boucle each une fois l'élément trouvé
          }
        });

        var lpgallery = document.getElementById('Lim_Product__gallery');
        lpgallery.swiper.slideTo(index);
      }
    });
  }

  initCharts() {

    this.DOM.ProductCharts.forEach((chart) => {

      const chartData = JSON.parse(JSON.parse(chart.dataset.chart));

      const ctx = chart.querySelector("canvas").getContext("2d");
      const chartLine = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [{
              data: chartData.datasets.data,
          }],
          labels: chartData.labels
        },
        options: {
          events: [],
          layout: {
            padding: {
              top: 20
            }
          },
          gridLines: {
            display:false
          },
          elements: {
            point: {
              radius:15,
              hoverRadius:15,
              borderColor: 'rgba(151, 208, 105, 1)',
              backgroundColor: 'rgba(151, 208, 105, 1)',
              borderWidth:0
            },
            line: {
              borderColor: 'rgba(151, 208, 105, 1)',
              backgroundColor: 'rgba(151, 208, 105, 0)',
              borderWidth: 1
            }
          },
          scales: {
            yAxes: [{
              ticks: { beginAtZero: true, padding: 10},
              gridLines: { tickMarkLength: 0, padding: 10 }
            }],
            xAxes: [{
              offset : 10,
              ticks: { suggestedMin: 15 },
              gridLines: { display: false }
            }]
          },
          legend: { display: false },
          hover: {mode: null},
          tooltips: { enabled: false },
          plotOptions: {
            series: {
              enableMouseTracking: false
            }
          },
          animation: {
            duration:0,
            onComplete:function () {

              const ctx = this.chart.ctx;

              this.data.datasets[0]._meta[this.id].data.forEach((point) => {

                const pointValue = this.data.datasets[0].data[point._index];
                const pointX = point._model.x;
                const pointY = point._model.y;

        				ctx.fontWeight = 'bold';
        				ctx.fillStyle = '#FFF';
        				ctx.textAlign="center";
                ctx.fillText(pointValue, pointX, pointY + 3);

              });

            }
          }
        },

      });

      this.Charts.push(chartLine);
    });

  }

  initTabs() {

    const TabsLink = this.DOM.ProductTabs.querySelectorAll('.Tabs__link');
    TabsLink.forEach((item, index) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();

        // Remove Prev Tabs
        const prevActives = this.DOM.ProductTabs.querySelectorAll('.is--active');
        prevActives.forEach((item) => {
          item.classList.remove('is--active');
        });


        // Show Active Tabs
        const targetAnchor = e.currentTarget.getAttribute('href');
        const targetTab = this.DOM.ProductTabs.querySelector(targetAnchor);

        e.currentTarget.classList.add('is--active');
        targetTab.classList.add('is--active');
      });

      if (index === 0) {
        item.classList.add('is--active');
        item.click();
      }
    });

  }

  initRelatedProducts() {

    this.RelatedProductsSwiper = new Swiper(this.DOM.ProductsSwiper, {
        spaceBetween: 40,
        slidesPerView: 4,
        breakpoints: {
          767: {
            slidesPerView: "auto",
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        }
    });

  }

  initNews() {

    this.NewsSwiper = new Swiper(this.DOM.NewsSwiper, {
        spaceBetween: 40,
        slidesPerView: 3,
        navigation: {
          nextEl: this.DOM.NewsSwiper.querySelector('.swiper-next'),
          prevEl: this.DOM.NewsSwiper.querySelector('.swiper-prev'),
        },
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
    });

  }


  initModals() {
    $('[data-modal]').magnificPopup();
  }
}
