import Highway from '@dogstudio/highway';

export default class SingleOffre extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Single Offre');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.formOffer = this.DOM.view.querySelector('#offerForm');
    this.DOM.formMessage = this.DOM.view.querySelector('#formMessage');

    this.DOM.fields = {
      post_id: this.DOM.view.querySelector('#post_id'),
      firstname: this.DOM.view.querySelector('#firstname'),
      name: this.DOM.view.querySelector('#name'),
      email: this.DOM.view.querySelector('#email'),
      phone: this.DOM.view.querySelector('#phone'),
      message: this.DOM.view.querySelector('#message'),
    };

    this.DOM.files = [
      this.DOM.view.querySelector('#file_1'),
      this.DOM.view.querySelector('#file_2'),
    ];
    this.initFilesInput();
    this.initFormOffer();
  }


  initFormOffer() {
    const url = this.DOM.formOffer.dataset.url;

    this.DOM.formOffer.addEventListener("submit", (e) => {
      e.preventDefault();
      document.body.style.cursor = "wait";
      const formData = new FormData();

      Object.keys(this.DOM.fields).forEach((key) => {
        const field = this.DOM.fields[key];
        formData.append(key, field.value);
      });

      for (const field of this.DOM.files){
        if (field.files.length){
          const file = field.files[0];
          formData.append('files[]', file, file.name);
        }
      }

      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          document.body.style.cursor = "default";
          if (xhr.status === 200) {
            this.DOM.formMessage.classList.remove("error");
            this.DOM.formMessage.innerText = xhr.responseText;
            this.DOM.formOffer.reset();
          } else {
            this.DOM.formMessage.classList.add("error");
            this.DOM.formMessage.innerText = `Une erreur est survenue: (${xhr.responseText})`;
          }
        }
      };
      xhr.send(formData);
    })
  }

  initFilesInput() {
    for (let fileInput of this.DOM.files){
      fileInput.addEventListener("change", (e)  => { this.onInputFileChange(fileInput)})
      this.onInputFileChange(fileInput)
    }
  }

  onInputFileChange(fileInput) {
    const textId = `${fileInput.id}_text`;
    const textField = this.DOM.view.querySelector(`#${textId}`)
    if (fileInput.files.length){
      if(fileInput.files[0].size > 10000000){
        alert("Max: 10Mo");
        fileInput.value = "";
        textField.innerText = "Votre pièce jointe"
      } else {
        textField.innerText = fileInput.files[0].name
      };
    } else {
      textField.innerText = "Votre pièce jointe"
    }
  }
}
