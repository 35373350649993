import Highway from '@dogstudio/highway';
import $ from "jquery";
import "magnific-popup";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class PageCavalierDashboard extends Highway.Renderer {

  onEnter() {
    if (window.DEVMODE) console.log('PageCavalierDashboard');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.modal = this.DOM.view.querySelector("#HorseEditModal");
    this.DOM.modalSteps= this.DOM.modal.querySelectorAll(".HorseModalStep");
    this.DOM.modalForm = this.DOM.modal.querySelector("#HorseEditForm");
    this.DOM.modalFormSubmit = this.DOM.modalForm.querySelector("button[type=submit]");
    this.DOM.modalFormIdField = this.DOM.modal.querySelector("#HorseId");
    this.DOM.modalFormImageField = this.DOM.modal.querySelector("#HorseImage");
    this.DOM.modalFormImagePreview = this.DOM.modal.querySelector("#HorseImagePreview");
    this.modalDocUrl = this.DOM.modalForm.dataset.url;

    this.DOM.modalDelete = this.DOM.view.querySelector("#HorseDeleteModal");
    this.DOM.SubmitDeleteHorse = this.DOM.modalDelete.querySelector("#SubmitDeleteHorse");


    const inputs = ["name", "race", "gender", "height", "birthdate", "weight"];
    this.DOM.formInputs = inputs.map((item) => this.DOM.view.querySelector(`#horse_${item}`));
    this.DOM.checkboxes = ["aged10","competition","gonflee","gene","manipulation","tousse_box","tousse_ration","essoufle","effort","jetage","perte_poids","baisse_forme","tics","sensible","coliques"];


    console.log(this.DOM.view.querySelector('#birthday'));

    this.initModals();

  }

  initModals() {
    const DOM = this.DOM;
    const onImageChange = (defaultSrc)  => this.onImageChange(defaultSrc);
    const onHorseStep = (step) => this.onHorseStep(step);
    const toggleStepsMessage = () => this.toggleStepsMessage();

    this.DOM.modal.querySelectorAll('input[type=radio]').forEach((radio) => {
      radio.addEventListener('change', () => this.toggleStepsMessage());
    })

    $('.EditBtn[data-modal], .Horse__add[data-modal]').magnificPopup({
      removalDelay:400,
      callbacks: {
        beforeOpen: function() {

          DOM.modalForm.reset()
          flatpickr("#horse_birthdate", {
            "locale": French,
            dateFormat: "d/m/Y",
          });

          onHorseStep(0);
          const moreStepsLink = DOM.modal.querySelectorAll('.moreStepsLink');
          moreStepsLink.forEach((item) => {
            item.addEventListener('click', (e) => {
              e.preventDefault();
              const formIsValid = DOM.modalForm.checkValidity();
              if (!formIsValid){
                DOM.modalFormSubmit.click();
                return;
              }
              onHorseStep(e.target.dataset.step);
            });
          });

          const clicked = this.st.el[0];
          if ("horse" in clicked.dataset){
            const horse = JSON.parse(clicked.dataset.horse);
            console.log(horse);
            DOM.modalFormIdField.value = horse.id;
            DOM.formInputs.forEach((field)=> {
              const key = field.id.replace("horse_", "");
              field.value = horse[key];
            });

            DOM.checkboxes.forEach((cb_name)=> {
              if (horse[cb_name] !== null){
                DOM.modalForm.querySelector(`input[name='${cb_name}'][value='${horse[cb_name]}']`).checked = true;
              } else {
                DOM.modalForm.querySelectorAll(`input[name='${cb_name}']`).forEach((cb) => cb.checked = false);
              }
            })

            DOM.modalFormImagePreview.dataset.src = `/upload/${horse.photo}`;
            lazySizes.loader.unveil(DOM.modalFormImagePreview);
            onImageChange(horse.photo);

          } else {
            DOM.modalForm.reset();
            DOM.modalFormImageField.value = "";
            DOM.modalFormIdField.value = "";
            onImageChange();
          }
          toggleStepsMessage();

        }
      },
    });

    $('.DeleteBtn[data-modal]').magnificPopup({
      removalDelay:400,
      callbacks: {
        beforeOpen: function() {
          const clicked = this.st.el[0];
          DOM.SubmitDeleteHorse.dataset.horseid = clicked.dataset.horseid
        }
      },
    });

    DOM.SubmitDeleteHorse.addEventListener('click', (e)=>{
      e.preventDefault();

      const formData = new FormData;
      formData.append("horse_id", DOM.SubmitDeleteHorse.dataset.horseid)
      const xhr = new XMLHttpRequest();
      xhr.open("POST", DOM.SubmitDeleteHorse.dataset.url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          location.reload();
        }
      };
      xhr.send(formData);
    })

    this.DOM.modalForm.addEventListener("submit", (e) => this.onPostHorse(e));
    this.DOM.modalFormImageField.addEventListener("change", (e) =>this.onImageChange());

  }

  onImageChange(defaultSrc="horses/default.png"){
    if (this.DOM.modalFormImageField.files.length){
      if (this.DOM.modalFormImageField.files[0].size > 10000000) {
        this.DOM.modalFormImageField.value="";
        alert("10Mo max.");
      }
      var reader = new FileReader();

      reader.onload = (e1) => {
        this.DOM.modalFormImagePreview.dataset.src = e1.target.result;
        lazySizes.loader.unveil(this.DOM.modalFormImagePreview);
      }

      reader.readAsDataURL(this.DOM.modalFormImageField.files[0]);
    } else {
      this.DOM.modalFormImagePreview.dataset.src = "/upload/"+defaultSrc
      lazySizes.loader.unveil(this.DOM.modalFormImagePreview);

    }
  }

  onPostHorse(e) {
    e.preventDefault();
    document.body.style.cursor = "wait";

    const errors_spans = this.DOM.modalForm.querySelectorAll('.error');
    for (let es of errors_spans){
      es.innerText = "";
    }

    const formData = new FormData();
    this.DOM.formInputs.map((item)  => {
      const name = item.id.replace("horse_", "");
      formData.append(name, item.value);
    });

    this.DOM.checkboxes.map((cb_name)  => {
      const selected = this.DOM.modalForm.querySelector(`input[name=${cb_name}]:checked`);
      if (selected) formData.append(cb_name, selected.value);
    });

    if (this.DOM.modalFormIdField.value){
      formData.append("horse_id", this.DOM.modalFormIdField.value);
    }

    if (this.DOM.modalFormImageField.files.length){
      formData.append('photo', this.DOM.modalFormImageField.files[0]);
    }

    const xhr = new XMLHttpRequest();
    xhr.open("POST", this.modalDocUrl, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          this.DOM.modalForm.reset();
          setTimeout(()=>{
            location.reload();
          }, 2000);
        } else {
          const errors = JSON.parse(xhr.responseText);
          Object.keys(errors).forEach((key) => {
            const id = `#error_${key}`;
            const message = errors[key];
            this.DOM.modalForm.querySelector(id).innerText = message;
          })
        }
        document.body.style.cursor = "default";
      }
    };
    xhr.send(formData);

  }

  onHorseStep(step) {

    this.DOM.modalSteps.forEach((item) => {
      if (item.classList.contains('is--active')) {
        const currentItem = item;
        currentItem.classList.remove('is--active');
      }
    });

    this.DOM.modalSteps[step].classList.add('is--active');
  }

  toggleStepsMessage(){
    console.log("toggleStepsMessage");

    this.DOM.modalSteps.forEach( (step) => {
      const message_yes = step.querySelector('.message--yes');
      const message_no = step.querySelector('.message--no');
      if (message_yes){
        const has_one_yes_answer = step.querySelector('input[type="radio"][value="1"]:checked');
        message_yes.style.display = has_one_yes_answer !== null ? "block" : "none";
      }
      if (message_no){
        const no_checkboxes = step.querySelectorAll('input[type="radio"][value="0"]');
        const checked_no_checkboxes = step.querySelectorAll('input[type="radio"][value="0"]:checked');
        const has_all_no_answer = (checked_no_checkboxes && (no_checkboxes.length === checked_no_checkboxes.length)) ;
        message_no.style.display = has_all_no_answer ? "block" : "none";
      }
    });
  }
}
